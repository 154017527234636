/*eslint-disable*/
import { useMutation, useQuery, useQueryClient } from "react-query";
import { get, getRelated } from "../services/api/properties";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { usePropertyContext } from "../context/property.";

const usePropertyMap = () => {
	const { payloadFilters, setPayloadFilters } = usePropertyContext();

	const [propertiesModified, setPropertiesModified] = useState([]);
	const [isLoadingSearch, setIsLoadingSearch] = useState(false);
	const [totalProperties, setTotalProperties] = useState(0);
	const [currentLatitude, setCurrentLatitude] = useState(undefined);
	const [currentLongitude, setCurrentLongitude] = useState(undefined);

	const queryClient = useQueryClient();

	const [center, setCenter] = useState({
		lat: 10.490280477764,
		lng: -66.821722984314,
	});

	const mapRef = useRef(null);

	const { data: properties, isLoading } = useQuery({
		queryKey: ["propertiesMap", payloadFilters],
		queryFn: () => get({ ...payloadFilters, limit: 10, offset: 0 }),
		queryFn: async () => {
			const agencyInfo = JSON.parse(localStorage.getItem("agencyInfo"));
			const response =
				(await agencyInfo) !== null
					? getRelated({
							userId: agencyInfo?.user?.id,
							...payloadFilters,
							limit: 10,
							offset: 0,
					  })
					: get({ ...payloadFilters, limit: 10, offset: 0 });
			return response;
		},
		onSuccess: (values) => {
			if (values?.data?.length > 0) {
				setCenter({
					lat: Number(values?.data[0].latitude),
					lng: Number(values?.data[0].longitude),
				});
			} else {
				setPropertiesModified([]);
				setTotalProperties(0);
			}
		},
	});

	const filterPropertiesInBound = async () => {
		setIsLoadingSearch(true);
		if (!mapRef?.current?.getBounds()) return;

		const { Hh: longitudeBound, ci: latitudeBound } =
			mapRef?.current?.getBounds();

			const latitude = { hi: latitudeBound?.hi, lo: latitudeBound?.lo };
		setCurrentLatitude(latitude);
		const longitude = { hi: longitudeBound?.hi, lo: longitudeBound?.lo };
		setCurrentLongitude(longitude);

		const agencyInfo = JSON.parse(localStorage.getItem("agencyInfo"));

		const payload = {
			limit: 10,
			offset: 0,
			latitude,
			longitude,
		};

		const response = agencyInfo
			? await getRelated({
					userId: agencyInfo?.user?.id,
					...payloadFilters,
					...payload,
			  })
			: await get({
					...payloadFilters,
					...payload,
			  });
			  
		setPropertiesModified(response?.data || []);
		setTotalProperties(response?.total || 0);
		setIsLoadingSearch(false);
	};

	const handleChangeCity = async () => {
		const newPayload = payloadFilters;
		delete newPayload.cityId;
		return await get(newPayload);
	};

	useEffect(() => {
		if (properties && properties?.data?.length > 0 && mapRef.current) {
			filterPropertiesInBound();
		}
	}, [properties, payloadFilters, mapRef.current]);

	useEffect(() => {
		queryClient.invalidateQueries("propertiesMap");
	}, [payloadFilters]);

	const { mutate: searchWithoutCity } = useMutation({
		mutationFn: handleChangeCity,
		onSuccess: (data) => {
			queryClient.setQueryData(["propertiesMap", payloadFilters], () => {
				return data;
			});
		},
	});

	const nextOrPrev = async (action) => {
		setIsLoadingSearch(true);
		let payload = {
			limit: 10,
			offset: action.selected,
			latitude: currentLatitude,
			longitude: currentLongitude,
		};

		const agencyInfo = JSON.parse(localStorage.getItem("agencyInfo"));

		const response = agencyInfo
			? await getRelated({
					...payloadFilters,
					...payload,
					userId: agencyInfo?.user?.id,
			  })
			: await get({
					...payloadFilters,
					...payload,
			  });

		setPropertiesModified(response?.data || []);
		setTotalProperties(response?.total || 0);
		setIsLoadingSearch(false);
	};

	const handleChangeBusinessType = async (value) => {
		setIsLoadingSearch(true);
		let payload = {
			limit: 10,
			offset: 0,
			latitude: currentLatitude,
			longitude: currentLongitude,
			...payloadFilters,
			businessTypeId: value,
		};

		setPayloadFilters(payload);
		setIsLoadingSearch(false);
	};

	const handleChangePropertyTypes = (value) => {
		setIsLoadingSearch(true);
		let payload = {
			limit: 10,
			offset: 0,
			latitude: currentLatitude,
			longitude: currentLongitude,
			...payloadFilters,
			propertyTypeId: value,
		};

		setPayloadFilters(payload);
		setIsLoadingSearch(false);
	};

	return {
		propertiesModified,
		setPropertiesModified,
		filterPropertiesInBound,
		isLoadingSearch,
		mapRef,
		properties,
		isLoading,
		center,
		searchWithoutCity,
		totalProperties,
		nextOrPrev,
		handleChangeBusinessType,
		handleChangePropertyTypes,
	};
};

export default usePropertyMap;

import HYDRA_API, { VT_API } from "./config/hydraAPI";

export const get = async (tokenVT) => {
  const url = `${process.env.REACT_APP_VT_API_URL}Country/ES`;

  const result = await VT_API(tokenVT).get(url);
  return result.data;
};

export const getAll = async () => {
  const url = `${process.env.REACT_APP_API_URL}country/get`;

  return await HYDRA_API().get(url);
};

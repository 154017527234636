import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { formatPrice } from "../../utils/format";
import Location from "../section-components/location";
import ButtonFixed from "../section-components/fixedButtons";
import useInterested from "../../hooks/useInterested";
import { useConfiguration } from "../../context/configurations";
import ModalInterested from "./interested/modal";
import { FixedButtonFavorite } from "../section-components/fixedFavorite";
import { Icon } from "@iconify/react/dist/iconify.js";

const ShopDetails = ({ property, plans, videos, featureProperties, t }) => {
  const {
    validateUser,
    isLoading,
    form,
    sendInformation,
    isAccesptRegister,
    setIsAccesptRegister,
    setSelectedProperty,
  } = useInterested(property);

  const { openInterested, setOpenInterested } = useConfiguration();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="ltn__shop-details-area pb-10 mt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
              <div className="ltn__blog-meta">
                <ul>
                  <li className="ltn__blog-category">
                    <Link className="bg-orange" to="/">
                      {property?.businessType}
                    </Link>
                  </li>
                  <li className="ltn__blog-date">{property?.statusType}</li>
                  <li className="ltn__blog-date">
                    <i className="far fa-calendar-alt" />
                    {property?.creationDate?.split("T")[0]}
                  </li>
                </ul>
              </div>
              <h1>{property?.title}</h1>
              <label>
                <span className="ltn__secondary-color">
                  <i className="flaticon-pin" />
                </span>{" "}
                {`${property?.city}, ${property?.state}`}
              </label>
              <h4 className="title-2">{t("Description")}</h4>
              <p>{property?.description}</p>
              <h4 className="title-2">{t("Detail")}</h4>
              <div className="property-detail-info-list section-bg-1 clearfix mb-60">
                {/* General Information */}
                <ul>
                  <li>
                    <label>{t("PropertyCode")}:</label>{" "}
                    <span>{property?.code}</span>
                  </li>
                  <li>
                    <label>{t("PropertyType")}:</label>{" "}
                    <span>{property?.propertyType}</span>
                  </li>

                  <li>
                    <label>{t("EstateType")}:</label>{" "}
                    <span>{property?.statusType}</span>
                  </li>

                  <hr />

                  <li>
                    <label>{t("Price")}:</label>{" "}
                    <span>
                      {formatPrice(property?.price || 0)}{" "}
                      {property?.currencyCode}
                    </span>
                  </li>
                  <li>
                    <label>{t("LeasingPrice")}:</label>{" "}
                    <span>
                      {formatPrice(property?.leasingPrice || 0)}{" "}
                      {property?.currencyCode}
                    </span>
                  </li>
                  <li>
                    <label>{t("AdminPrice")}:</label>{" "}
                    <span>
                      {formatPrice(property?.adminPrice || 0)}{" "}
                      {property?.currencyCode}
                    </span>
                  </li>

                  <hr />

                  <li>
                    <label>{t("GroundArea")}:</label>{" "}
                    <span>
                      {property?.landArea} {property?.measurementCode}
                    </span>
                  </li>
                  <li>
                    <label>{t("BuildArea")}:</label>{" "}
                    <span>
                      {property?.builtArea} {property?.measurementCode}
                    </span>
                  </li>
                  <li>
                    <label>{t("PrivateArea")}: </label>{" "}
                    <span>
                      {property?.privateArea} {property?.measurementCode}
                    </span>
                  </li>
                </ul>

                <ul>
                  {property?.buildYear && (
                    <>
                      <li>
                        <label>{t("YearBuilt")}:</label>{" "}
                        <span>{property?.buildYear}</span>
                      </li>

                      <hr />
                    </>
                  )}

                  <li>
                    <label>{t("Bedrooms")}:</label>{" "}
                    <span>{property?.roomsQuantity}</span>
                  </li>
                  <li>
                    <label>{t("Bath")}:</label>{" "}
                    <span>{property?.bathroomsQuantity}</span>
                  </li>
                  <li>
                    <label>{t("ParkingNumber")}:</label>{" "}
                    <span>{property?.parkingQuantity}</span>
                  </li>

                  <hr />

                  <li>
                    <label>{t("ServiceRoom")}:</label>{" "}
                    <span>{property?.serviceRoom ? t("Yes") : "No"}</span>
                  </li>
                  <li>
                    <label>{t("UtilityRoom")}:</label>{" "}
                    <span>{property?.utilityRoom ? t("Yes") : "No"}</span>
                  </li>
                </ul>
              </div>

              {property?.showSeller && (
                <div>
                  <h4 className="title-2">{t("Seller")}</h4>
                  <div className="seller-container">
                    {property?.avatar && (
                      <div className="seller-image">
                        <img src={property?.avatar} alt="" />
                      </div>
                    )}
                    <div className="seller-description">
                      <h5>{property?.seller} </h5>
                      <span>
                        <i class="fa-solid fa-envelope"></i>{" "}
                        {property?.sellerEmail}
                      </span>
                      <br />
                      {property?.showSellerContact && (
                        <span>
                          <i class="fa-solid fa-phone"></i> +
                          {property?.sellerPhone}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {property?.propertyFeatures?.length > 0 && (
                <>
                  <h4 className="title-2">{t("Features")}</h4>
                  <div className="property-detail-feature-list clearfix mb-45">
                    <ul>
                      {property?.propertyFeatures?.map((item) => (
                        <li key={item.id}>
                          <div className="property-detail-feature-list-item">
                            <i className={`${item?.features?.icon}`} />
                            <div>
                              <h6>{item?.features?.name}</h6>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              )}

              {/* APARTMENTS PLAN AREA START */}
              {plans?.length > 0 && (
                <div>
                  <h4 className="title-2">{t("ConstructionPlans")}</h4>
                  <div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
                    <div className="col-lg-12 tab-content-plans">
                      {plans?.map((item) => (
                        <div className="apartments-plan-detail-img">
                          <img src={item.path} alt="/" />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {/* PRPERTIES VIDEOS */}
              {videos?.length > 0 && (
                <div>
                  <h4 className="title-2">{t("Videos")}</h4>
                  <div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
                    <div className="col-lg-12 tab-content-plans">
                      <div className="apartments-plan-detail-img">
                        <video
                          src="https://www.youtube.com/watch?v=DjQDe4j3nhw"
                          width="300"
                          height="300"
                        ></video>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Features */}
              {featureProperties && (
                <>
                  <h4 className="title-2">{t("Features")}</h4>
                  <div className="property-detail-feature-list clearfix mb-45">
                    <ul>
                      {featureProperties?.map((item) => (
                        <li key={item.id}>
                          <div className="property-detail-feature-list-item">
                            <Icon
                              icon={item.featureIcon}
                              style={{
                                fontSize: "25px",
                                marginRight: 10,
                              }}
                            />
                            <div>
                              <h6>{item.featureName}</h6>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              )}

              {/* LOCATION */}
              <h4 className="title-2">{t("Location")}</h4>
              {property?.address && (
                <span>
                  {" "}
                  <strong>{t("Address")}: </strong>
                  {property?.address}
                </span>
              )}
              <br />
              {property?.zipCode && (
                <span>
                  <strong>{t("ZipCode")}: </strong> {property?.zipCode}
                </span>
              )}

              {property && (
                <Location
                  lat={Number(property?.latitude)}
                  lng={Number(property?.longitude)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {property && (
        <>
          <FixedButtonFavorite id={property?.id} />
          <ButtonFixed
            validateUser={validateUser}
            setSelectedProperty={setSelectedProperty}
            property={property}
            t={t}
          />
        </>
      )}
      {openInterested && (
        <ModalInterested
          form={form}
          setOpen={setOpenInterested}
          open={openInterested}
          sendInformation={sendInformation}
          isAccesptRegister={isAccesptRegister}
          setIsAccesptRegister={setIsAccesptRegister}
          isLoading={isLoading}
          t={t}
        />
      )}
    </div>
  );
};

export default ShopDetails;

/*eslint-disable*/
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAgency } from "../../context/agency";
import Social from "../section-components/social";
import Agency from "../shared/agency";
import Language from "./language";

const Navbar = ({ user, logOut }) => {
  const { t } = useTranslation();

  const { agency, logOut: logOutAgency } = useAgency();

  return (
    <div>
      <header className="ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu ltn__header-transparent--- gradient-color-4---">
        <div className="ltn__header-top-area section-bg-6 top-area-color-white---">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <div className="ltn__top-bar-menu">
                  <ul>
                    <li>
                      <a href="mailto:info@ubikme.net?Subject=Flower%20greetings%20to%20you">
                        <i className="icon-mail" /> info@ubikme.net
                      </a>
                    </li>
                    <li>
                      <a href="locations.html">
                        <i className="icon-placeholder" /> 8725 NW 18th terrace
                        ste 303 Doral FL 33172
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-5">
                <div className="top-bar-right text-end">
                  <div className="ltn__top-bar-menu">
                    <ul>
                      <li>
                        <Language />
                      </li>
                      <li>
                        <Social />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-white">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="site-logo-wrap">
                  <div className="site-logo go-top">
                    <Link to="/">
                      <img
                        className="logo-img-nav"
                        src={
                          "https://cdn1.visiontravel.net/ubikme/logo/logo.png"
                        }
                        alt="Logo"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col header-menu-column">
                <div className="header-menu d-none d-xl-block">
                  <nav>
                    <div className="ltn__main-menu go-top">
                      <ul>
                        <li className="menu-icon">
                          <Link to="/">{t("Home")}</Link>
                        </li>
                        <li className="menu-icon">
                          <Link to="/shop">{t("Estate")}</Link>
                        </li>
                        <li className="menu-icon">
                          <Link to="/about">{t("About")}</Link>
                        </li>
                        <li className="menu-icon">
                          <Link to="/contact">{t("Contact")}</Link>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
              <div className="col ltn__header-options ltn__header-options-2 mb-sm-20">
                <div className="ltn__drop-menu user-menu">
                  <ul>
                    <li>
                      <Link to={user ? "/my-account" : "/"}>
                        <i className="fa-solid fa-user"></i>
                      </Link>
                      {!user ? (
                        <ul className="go-top">
                          <li>
                            <Link to="/login">{t("SignIn")}</Link>
                          </li>
                          <li>
                            <Link to="/register">{t("Register")}</Link>
                          </li>
                        </ul>
                      ) : (
                        <ul className="go-top">
                          <li>
                            <Link to="/my-account">{t("MyAccount")}</Link>
                          </li>
                          <li>
                            <Link to="/login" onClick={logOut}>
                              {t("LogOut")}
                            </Link>
                          </li>
                        </ul>
                      )}
                    </li>
                  </ul>
                </div>
                {/* wishlist-menu */}
                {user && (
                  <div className="ltn__drop-menu user-menu">
                    <ul>
                      <li>
                        <Link to={user ? "/wishlist" : "/"}>
                          <i className="fa-regular fa-heart"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
                {agency && (
                  <Agency agency={agency} style="dark" logOut={logOutAgency} />
                )}
                <div className="col--- ltn__header-options ltn__header-options-2 ">
                  <div className="mobile-menu-toggle d-xl-none">
                    <a
                      href="#ltn__utilize-mobile-menu"
                      className="ltn__utilize-toggle"
                    >
                      <svg viewBox="0 0 800 600">
                        <path
                          d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                          id="top"
                        />
                        <path d="M300,320 L540,320" id="middle" />
                        <path
                          d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                          id="bottom"
                          transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div
        id="ltn__utilize-mobile-menu"
        className="ltn__utilize ltn__utilize-mobile-menu"
      >
        <div className="ltn__utilize-menu-inner ltn__scrollbar">
          <div className="ltn__utilize-menu-head">
            <div className="site-logo">
              <Link to="/">
                <img
                  src={"https://cdn1.visiontravel.net/ubikme/logo/logo.png"}
                  alt="Logo"
                  className="logo-img-nav"
                />
              </Link>
            </div>
            <button className="ltn__utilize-close">×</button>
          </div>
          <div className="ltn__utilize-menu">
            <ul>
              <li>
                <a href="/">{t("Home")}</a>
              </li>
              <li>
                <Link to="/shop">{t("Estate")}</Link>
              </li>
              <li>
                <Link to="/about">{t("About")}</Link>
              </li>
              <li>
                <Link to="/contact">{t("Contact")}</Link>
              </li>
            </ul>
          </div>
          <div className="ltn__social-media-2">
            <ul>
              <li>
                <a
                  href="https://www.instagram.com/ubikme_net/?igshid=YmMyMTA2M2Y%3D"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Instagram"
                >
                  <i className="fab fa-instagram" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

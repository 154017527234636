import { useQuery } from "react-query";
import { getAll as getCountries } from "../../services/api/countries";

export const useGet = () => {
  const { data: countries, isLoading } = useQuery({
    queryKey: ["country"],
    queryFn: async () => {
      const response = await getCountries();

      return response.data.data;
    },
  });

  return {
    countries,
    isLoading,
  };
};

import { Form } from "antd";
import { useTranslation } from "react-i18next";
import useNotification from "../utils/notifications";
import { create, get, update } from "../services/api/address";
import { useUser } from "../context/user";
import { useState } from "react";
import { useQuery } from "react-query";

const useAddress = () => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const { showNotification } = useNotification();
	const [isLoading, setIsLoading] = useState(false);
	const { user } = useUser();

	const { data: address } = useQuery("address", {
		queryFn: async () => {
			const response = await get({ userId: user.id });
			return response;
		},
		onSuccess: (res) => {
			if (res?.length > 0)
				form.setFieldsValue({
					...res[0],
					address1: res[0].addressLine1,
					address2: res[0].addressLine2,
					zipCode: res[0].postalCode,
					cityName: res[0].city.id,
					cityId: res[0].city.name,
				});
		},
	});

	const sendInformation = async () => {
		setIsLoading(true);
		if (!address) {
			const response = await create({
				...form.getFieldsValue(),
				userId: user.id,
				createdBy: user.email,
			});
			if (response.success)
				showNotification("success", t("MessageSuccessSend"));
			else showNotification("error", t("MessageErrorSend"));
		} else {
			const response = await update({
				...form.getFieldsValue(),
				addressLine1: form.getFieldValue("address1"),
				addressLine2: form.getFieldValue("address2"),
				id: address[0].id,
				userId: user.id,
				cityId:
					typeof form.getFieldValue("cityId") === "string"
						? form.getFieldValue("cityName")
						: form.getFieldValue("cityId"),
				createdBy: user.email,
			});
			if (response.success)
				showNotification("success", t("MessageSuccessSend"));
			else showNotification("error", t("MessageErrorSend"));
		}
		setIsLoading(false);
	};

	return {
		form,
		sendInformation,
		isLoading,
		address,
	};
};

export default useAddress;

import { useQuery } from "react-query";
import { get as getStates } from "../../services/api/states";

export const useGet = (countryId) => {
  const {
    data: states,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["state"],
    queryFn: async () => {
      if (!countryId) return [];

      const response = await getStates({ countryId: countryId });

      return response.data.data;
    },
  });

  return {
    states,
    isLoading,
    refetch,
  };
};

import axios from "axios";
import { getBrowser } from "../../../utils/format";
const lang = localStorage.getItem("i18nextLng")

export const HYDRA_API = (ipAddress) => {
  const userAgent = getBrowser()

  let headers = {
    "Accept-Language": lang || "es",
    'X-Ubikme-Client-IP': ipAddress,
    'X-Ubikme-User-Agent': userAgent
  };

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers,
  });

  return instance;
};

export const VT_API = (tokenVT) => {
  let headers = {
    "Accept-Language": lang || "es",
    "authorization": `bearer ${tokenVT}`
  };

  const instance = axios.create({
    baseURL: process.env.REACT_APP_VT_API_URL,
    headers,
  });

  return instance;
};

export default HYDRA_API;

import React from "react";
import { Form, Input, Row, Col, Select } from "antd";
const AddressForm = ({
  countries,
  form,
  sendInformation,
  setLocation,
  cities,
  isLoading,
  address,
  t,
}) => {
  const { Option } = Select;

  return (
    <Form form={form} layout="vertical" onFinish={sendInformation}>
      <Row gutter={16}>
        <Col xs={24} sm={12} md={12} xl={12}>
          <Form.Item
            name="cityId"
            label={<label className="filter-labels">{t("City")}</label>}
            rules={[{ required: true, message: t("RequiredField") }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              notFoundContent={t("TypeAtLeast")}
              onSearch={(value) => setLocation(value)}
            >
              {cities?.map((city, key) => (
                <Option className="options-select" key={key} value={city.id}>
                  {city.name}, {city.state.name}, {city.state.country.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} xl={12}>
          <Form.Item
            name="address1"
            label={<span className="form-labels">{t("Address")}</span>}
            rules={[{ required: true, message: t("RequiredField") }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} xl={12} className="item-form">
          <Form.Item
            name="address2"
            label={<span className="form-labels">{t("AditionalAddress")}</span>}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} xl={12} className="item-form">
          <Form.Item
            name="zipCode"
            label={<span className="form-labels">{t("ZipCode")}</span>}
            rules={[
              { required: true, message: t("RequiredField") },
              { min: 4, message: t("MinLength").replace("{N}", 4) },
              { max: 10, message: t("MaxLength").replace("{N}", 10) },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} xl={12} className="item-form">
          <Form.Item
            name="indicative"
            label={<span className="form-labels">{t("Indicative")}</span>}
            rules={[{ required: true, message: t("RequiredField") }]}
          >
            <Select showSearch optionFilterProp="children">
              {countries?.map((country, key) => (
                <Option
                  className="options-select"
                  key={key}
                  value={country.indicative}
                >
                  {country.name} +{country.indicative}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} xl={12} className="item-form">
          <Form.Item
            name="phone"
            label={<span className="form-labels">{t("Phone")}</span>}
            rules={[
              { required: true, message: t("RequiredField") },
              { min: 8, message: t("MinLength").replace("{N}", 8) },
              { max: 12, message: t("MaxLength").replace("{N}", 12) },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <div className="btn-wrapper mt-0">
        <button
          className="btn theme-btn-1 btn-effect-1 text-uppercase"
          type="submit"
          disabled={isLoading}
        >
          {address?.length > 0 ? t("Update") : t("Send")}
        </button>
      </div>
    </Form>
  );
};

export default AddressForm;

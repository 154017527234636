import React from "react";
import { LoginOutlined } from "@ant-design/icons";

const Agency = ({ agency, style, logOut }) => {
  console.log(agency);
  return (
    <div className="ltn__drop-menu user-menu">
      <div className="container-agency-navbar">
        <div className="agency-image-container">
          <img
            className="agency-image"
            src={agency?.imageUrl ? agency?.imageUrl : agency?.avatar}
            alt="avatar"
          />
        </div>
        <div className="agency-info">
          <p
            className={
              style === "ligth" ? "agency-info-name" : "agency-info-name-dark"
            }
          >
            {agency?.agencyName}
          </p>
          <p
            className={
              style === "ligth" ? "agency-info-code" : "agency-info-code-dark"
            }
          >
            {agency?.code}
            <span onClick={logOut}>
              <LoginOutlined className="icon-logout" />
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Agency;

import React from "react";
import Navbar from "./global-components/navbar1";
import PageHeader from "./global-components/page-header";
import ProductDetails from "./shop-components/shop-details";
import Footer from "./global-components/footer";
import usePropertyDetail from "../hooks/usePropertyDetail";
import ProductSlider from "./shop-components/product-slider-v1";
import { useTranslation } from "react-i18next";
import { useUser } from "../context/user";
import useResource from "../hooks/useResource";

import { useGet as useFeatureProperty } from "../hooks/featureProperty/useGet";

import { Redirect } from "react-router-dom";

const Product_Details = () => {
  const { t } = useTranslation();
  const { property, isLoading } = usePropertyDetail();
  const { images, plans, videos } = useResource(property?.id);

  const { featureProperties, isLoading: isFeaturePropertyLoading } =
    useFeatureProperty(property?.id);

  const { user, logOut } = useUser();

  return isLoading || isFeaturePropertyLoading ? (
    <div class="cv-spinner">
      <span class="spinner"></span>
    </div>
  ) : property ? (
    <div>
      <Navbar user={user} logOut={logOut} />
      <PageHeader headertitle={t("ProductDetail")} customclass="mb-0" t={t} />
      <ProductSlider images={images} />
      <ProductDetails
        property={property}
        plans={plans}
        videos={videos}
        featureProperties={featureProperties}
        t={t}
      />
      <Footer t={t} />
    </div>
  ) : (
    <Redirect to="/404" />
  );
};

export default Product_Details;

import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getByText } from "../services/api/cities";
import get from "../services/api/properties";
import { usePropertyContext } from "../context/property.";

const usePropertyFilters = () => {
  const { setPayloadFilters, setSelectedCity, selectedCity, payloadFilters } =
    usePropertyContext();

  const [propertyType, setPropertyType] = useState([]);
  const [businessType, setBusinessType] = useState([]);
  const [stateType, setStateType] = useState([]);
  const [city, setCity] = useState(0);
  const [location, setLocation] = useState("");
  const [priceRange, setPriceRange] = useState({ min: 0, max: 10000000 });
  const [payload, setPayload] = useState({});

  const queryClient = useQueryClient();

  const handleChangeCheckBox = async (value) => {
    let propertyTypeId = propertyType;
    let businessTypeId = businessType;
    let stateTypeId = stateType;
    const { target } = value;

    if (target.name === "propertyTypeId") {
      if (target.checked) {
        propertyTypeId.push(target.value);
        setPropertyType(propertyTypeId);
      } else {
        let removeItemIndex = propertyTypeId.indexOf(target.value);
        if (removeItemIndex !== -1) propertyTypeId.splice(removeItemIndex, 1);
        setPropertyType(propertyTypeId);
      }
    }
    if (target.name === "businessTypeId") {
      if (target.checked) {
        businessTypeId.push(target.value);
        setBusinessType(businessTypeId);
      } else {
        let removeItemIndex = businessTypeId.indexOf(target.value);
        if (removeItemIndex !== -1) businessTypeId.splice(removeItemIndex, 1);
        setBusinessType(businessTypeId);
      }
    }
    if (target.name === "statusTypeId") {
      if (target.checked) {
        stateTypeId.push(target.value);
        setStateType(stateTypeId);
      } else {
        let removeItemIndex = stateTypeId.indexOf(target.value);
        if (removeItemIndex !== -1) stateTypeId.splice(removeItemIndex, 1);
        setStateType(stateTypeId);
      }
    }
    const agencyInfo = JSON.parse(localStorage.getItem("agencyInfo"));
    const payload = {
      propertyTypeId:
        propertyTypeId.length > 0 ? propertyTypeId?.join() : undefined,
      businessTypeId:
        businessTypeId.length > 0 ? businessTypeId?.join() : undefined,
      statusTypeId: stateTypeId.length > 0 ? stateTypeId?.join() : undefined,
      cityId: city !== 0 ? city : undefined,
      ownerId: agencyInfo?.user?.id,
      sellerId: agencyInfo?.user?.id,
      priceRange,
    };

    setPayload(payload);
    setPayloadFilters(payload);
    return await get({ ...payload, limit: 10, offset: 0 });
  };

  const { mutate: handleChange, isLoading: isLoadingMut } = useMutation({
    mutationFn: handleChangeCheckBox,
    onSuccess: (data) => {
      queryClient.setQueryData(["properties"], () => {
        return data;
      });
    },
  });

  const handleChangeLocation = async (value) => {
    const agencyInfo = JSON.parse(localStorage.getItem("agencyInfo"));
    const payload = {
      propertyTypeId: propertyType.length > 0 ? propertyType : undefined,
      businessTypeId: businessType.length > 0 ? businessType : undefined,
      statusTypeId: stateType.length > 0 ? stateType : undefined,
      cityId: value,
      ownerId: agencyInfo?.user?.id,
      sellerId: agencyInfo?.user?.id,
      priceRange,
    };
    setCity(value);
    setPayload(payload);
    setPayloadFilters(payload);
    return await get({ ...payload, limit: 10, offset: 0 });
  };

  const { mutate: handleChangeSelect, isLoading: isLoadingSelect } =
    useMutation({
      mutationFn: handleChangeLocation,
      onSuccess: (data) => {
        queryClient.setQueryData(["properties"], () => {
          return data;
        });
      },
    });

  const { data: cities } = useQuery({
    queryKey: ["cities"],
    enabled: location.length >= 3,
    queryFn: async () => {
      return getByText(location);
    },
  });

  const handleChangePriceRange = async (values) => {
    const agencyInfo = JSON.parse(localStorage.getItem("agencyInfo"));
    const payload = {
      propertyTypeId: propertyType.length > 0 ? propertyType : undefined,
      businessTypeId: businessType.length > 0 ? businessType : undefined,
      statusTypeId: stateType.length > 0 ? stateType : undefined,
      cityId: city !== 0 ? city : undefined,
      ownerId: agencyInfo?.user?.id,
      sellerId: agencyInfo?.user?.id,
      priceRange: { min: values[0], max: values[1] },
    };
    setPayload(payload);
    setPriceRange({ min: values[0], max: values[1] });
    setPayloadFilters(payload);
    return await get({ ...payload, limit: 10, offset: 0 });
  };

  const { mutate: handleChangePrice } = useMutation({
    mutationFn: handleChangePriceRange,
    onSuccess: (data) => {
      queryClient.setQueryData(["properties"], () => {
        return data;
      });
    },
  });

  return {
    handleChange,
    isLoadingMut,
    handleChangeSelect,
    isLoadingSelect,
    setLocation,
    cities,
    handleChangePrice,
    priceRange,
    payload,
    setSelectedCity,
    selectedCity,
    payloadFilters,
  };
};

export default usePropertyFilters;

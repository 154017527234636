import HYDRA_API from "./config/hydraAPI";

export const getByText = async (text) => {
  const url = `${process.env.REACT_APP_API_URL}city/getByText/${text}`;

  const result = await HYDRA_API().get(url);
  return result.data.data;
};

export const get = async (payload) => {
  const url = `${process.env.REACT_APP_API_URL}city/get`;
  return await HYDRA_API().post(url, payload);
};

export default getByText;

import { Col, Row } from "antd";
import Sidebar from "../../shop-sidebar";
import { Link } from "react-router-dom";
import { formatPrice } from "../../../../utils/format";
import ReactPaginate from "react-paginate";

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const List = ({
  properties,
  addWishList,
  propertyTypes,
  businessType,
  statusType,
  handleChangeCheckBox,
  handleChangeLocation,
  setLocation,
  cities,
  handleChangePrice,
  priceRange,
  validateUser,
  setSelectedProperty,
  setSelectedCity,
  nextOrPrev,
  t,
  isLoading,
}) => {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 42, color: "#ffc400" }} spin />
  );

  return (
    <div className="ltn__product-area ltn__product-gutter">
      <div className="container">
        <Row gutter={16}>
          <Col xs={24} sm={24} md={8} xl={8}>
            <Sidebar
              propertyTypes={propertyTypes}
              businessType={businessType}
              statusType={statusType}
              handleChangeCheckBox={handleChangeCheckBox}
              handleChangeLocation={handleChangeLocation}
              setLocation={setLocation}
              cities={cities}
              handleChangePrice={handleChangePrice}
              priceRange={priceRange}
              setSelectedCity={setSelectedCity}
              t={t}
            />
          </Col>
          <Col xs={24} sm={24} md={16} xl={16}>
            <div className="tab-content">
              <div
                className="tab-pane fade active show"
                id="liton_product_grid"
              >
                <div className="ltn__product-tab-content-inner ltn__product-grid-view">
                  <div className="row">
                    {/* ltn__product-item */}
                    {isLoading ? (
                      <div className="spin-list-map-container">
                        <div className="spin-list-map">
                          <Spin indicator={antIcon} />
                        </div>
                        <p className="text-spin-list-map">
                          {t("WaitProperties")}
                        </p>
                      </div>
                    ) : (
                      <>
                        {properties?.data?.map((item, index) => (
                          <div className="col-xl-6 col-sm-6 col-12" key={index}>
                            <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                              <div className="product-img go-top">
                                <Link
                                  to={`/product-details/${item?.seoCode}/${item?.id}`}
                                >
                                  <img
                                    className="properties-list-img"
                                    src={
                                      item?.thumbnail ??
                                      "https://cdn1.visiontravel.net/ubikme/properties/images/default.gif"
                                    }
                                    alt="/"
                                  />
                                </Link>
                              </div>
                              <div className="product-info">
                                <div className="product-badge">
                                  <ul>
                                    <li className="sale-badg">
                                      {item.businessType} - {item.propertyType}
                                    </li>
                                  </ul>
                                </div>
                                <h2 className="product-title go-top">
                                  <Link
                                    to={`/product-details/${item?.seoCode}/${item?.id}`}
                                  >
                                    {item.title}
                                  </Link>
                                </h2>
                                <div className="product-img-location go-top">
                                  <ul>
                                    <li>
                                      <Link to="/contact">
                                        <i className="flaticon-pin" />{" "}
                                        {item?.city}, {item?.state}
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                                <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                                  <li>
                                    <span>{item?.roomsQuantity} </span>
                                    {t("Bedrooms")}
                                  </li>
                                  <li>
                                    <span>{item?.bathroomsQuantity} </span>
                                    {t("Bath")}
                                  </li>
                                  <li>
                                    <span>{item?.privateArea} </span>
                                    {item?.measurementCode}
                                  </li>
                                </ul>

                                <div className="actions-product-list">
                                  <div
                                    className="product-hover-action"
                                    onClick={() => addWishList(item.id)}
                                  >
                                    <ul>
                                      <li className="wishlist-button">
                                        <span title="Agregar">
                                          <i className="flaticon-heart-1" />
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                  {item?.showSellerContact &&
                                    item?.sellerPhone && (
                                      <div
                                        className="product-hover-action"
                                        onClick={() =>
                                          window.open(
                                            `https://wa.me/${item.sellerPhone}`,
                                            "_blank"
                                          )
                                        }
                                      >
                                        <ul>
                                          <li className="wishlist-button">
                                            <span title="Hablar con el vendedor">
                                              <i className="fa-brands fa-whatsapp fa-lg" />
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    )}
                                  <div
                                    className="product-hover-action"
                                    onClick={() => {
                                      validateUser(item.id);
                                      setSelectedProperty(item.id);
                                    }}
                                  >
                                    <ul>
                                      <li className="wishlist-button">
                                        <span title="¿Estás interesado?, envía tus datos para comunicarnos lo más pronto posible.">
                                          <i className="fa-sharp fa-solid fa-house-laptop fa-lg" />
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="product-info-bottom">
                                <div className="product-price">
                                  <span>
                                    {formatPrice(item?.price)}{" "}
                                    {item?.currencyCode}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  padding: 20,
                  boxSizing: "border-box",
                  width: "100%",
                  height: "100%",
                }}
              >
                <ReactPaginate
                  activeClassName={"item-pagination active-pagination "}
                  breakClassName={"item-pagination break-me "}
                  breakLabel={"..."}
                  containerClassName={"pagination"}
                  disabledClassName={"disabled-page"}
                  marginPagesDisplayed={2}
                  nextClassName={"item-pagination next-pagination "}
                  nextLabel=">"
                  onPageChange={nextOrPrev}
                  pageCount={properties?.total / 10}
                  pageClassName={"item-pagination pagination-page "}
                  pageRangeDisplayed={3}
                  previousClassName={"item-pagination previous-pagination"}
                  previousLabel="<"
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default List;
